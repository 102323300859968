@tailwind base;
@tailwind components;
@tailwind utilities;


body.overflow-hidden {
    overflow: hidden;
  }
  


